/* eslint-disable @next/next/no-html-link-for-pages */
import { useRouter } from 'next/router';
import React from 'react';
import { useAsync } from 'react-use';

import RenderBlocks, { SanityBlock } from 'components/cms-blocks/render-blocks';
import Main from 'components/common/main';
import { homePageQuery } from 'framework/sanity/queries';
import { initializeApollo } from 'utils/apollo-client';
import {
  isInPreviewMode,
  mapBlocks,
  getClient,
  usePreviewSubscription,
} from 'utils/sanity';

interface HomeProps {
  data: {
    page: {
      blocks: SanityBlock[];
    };
  };
}

export default function Home({ data }: HomeProps) {
  const router = useRouter();
  const previewEnabled = isInPreviewMode(router);

  const { data: page } = usePreviewSubscription(homePageQuery, {
    initialData: data.page,
    enabled: previewEnabled,
  });

  const { value: mappedBlocks } = useAsync(async () => {
    if (!previewEnabled) {
      return page.blocks;
    }

    return mapBlocks(page.blocks);
  }, [previewEnabled, page.blocks]);

  return (
    <Main>
      <RenderBlocks
        blocks={(previewEnabled ? mappedBlocks : page.blocks) || []}
      />
    </Main>
  );
}

export async function getStaticProps({ preview = false }) {
  const apolloClient = initializeApollo();
  const page = await getClient().fetch(homePageQuery);

  const blocks = await mapBlocks(page.blocks, apolloClient);

  return {
    revalidate: 1,
    props: {
      preview,
      data: {
        page: {
          blocks,
        },
      },
    },
  };
}
